<template>
  <div id="app">
    <select v-model="selectedOption" @change="fetchData(selectedOption)" style="font-size: larger; width: 200px;">
      <option v-for="(option, index) in options" :key="index" :value="option.value">{{ option.label }}</option>
    </select>


    <table>
      <thead>
        <tr>
          <th>序号</th>
          <th>词</th>
          <th>词义</th>
          <th>例句</th>
          <th>AI对话</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in dataList" :key="index">
          <td>{{ index + 1 }}</td>
          <td @click="toggleDetail(item.word, selectedOption)">{{ item.word }}</td>
          <td>{{ item.means }}</td>
          <td>
            <!-- 用于显示详情的部分 -->
            <div v-if="showDetail === item.word && audioLinks" class="details">
              <p class="translation">UK : <audio controls :src="audioLinks.mp3Uk"></audio></p>
              <p class="translation">US : <audio controls :src="audioLinks.mp3US"></audio></p>
              <div v-for="(sentenceInfo, idx) in audioLinks.sentenceInfoList" :key="idx" class="sentence">
                <p class="sentence-text">{{ sentenceInfo.sentence }}</p>
                <p class="translation">{{ sentenceInfo.trans }}</p>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      selectedOption: '01_自然地理',
      dataList: [],
      audioLinks: null, // 用于存储音频链接和例句的对象
      showDetail: '', // 用于指示哪个单词的详情应该显示
      options: [
        { value: '01_自然地理', label: '01_自然地理' },
        { value: '02_植物研究', label: '02_植物研究' },
        { value: '03_动物保护', label: '03_动物保护' },
        { value: '04_太空探索', label: '04_太空探索' },
        { value: '05_学校教育', label: '05_学校教育' },
        { value: '06_科技发明', label: '06_科技发明' },
        { value: '07_文化历史', label: '07_文化历史' },
        { value: '08_语言演化', label: '08_语言演化' },
        { value: '09_娱乐运动', label: '09_娱乐运动' },
        { value: '10_物品材料', label: '10_物品材料' },
        { value: '11_时尚潮流', label: '11_时尚潮流' },
        { value: '12_饮食健康', label: '12_饮食健康' },
        { value: '13_建筑场所', label: '13_建筑场所' },
        { value: '14_交通旅行', label: '14_交通旅行' },
        { value: '15_国家政府', label: '15_国家政府' },
        { value: '16_社会经济', label: '16_社会经济' },
        { value: '17_法律法规', label: '17_法律法规' },
        { value: '18_沙场争锋', label: '18_沙场争锋' },
        { value: '19_社会角色', label: '19_社会角色' },
        { value: '20_行为动作', label: '20_行为动作' },
        { value: '21_身心健康', label: '21_身心健康' },
        { value: '22_时间日期', label: '22_时间日期' }
      ]
    };
  },
  mounted() {
    // 在组件加载完成后调用方法获取数据
    this.fetchData('01_自然地理'); // 默认第一个
  },
  methods: {
	async fetchData(theme) {
      try {
        const response = await axios.get(`https://ielt.tech/api/ielts/${encodeURIComponent(theme)}`);
        
        // 将返回的数据赋值给 dataList
        this.dataList = response.data.data;
      } catch (error) {
        console.error(error);
        // 处理错误
      }
    },

    async toggleDetail(word, selectedOption) {
      if (this.showDetail === word) {
        this.showDetail = '';
      } else {
        try {
          const response = await axios.get(`https://ielt.tech/api/cambridge-proxy/${encodeURIComponent(selectedOption)}/${encodeURIComponent(word)}`);
          // 处理服务器代理返回的数据
          this.audioLinks = response.data.data;
          this.showDetail = word; // 设置显示详情的单词
        } catch (error) {
          console.error(error);
          // 处理错误
        }
      }
    }

  }
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}
table, th, td {
  border: 1px solid #ddd;
}
th, td {
  padding: 10px;
  text-align: left;
}
th {
  background-color: #f2f2f2;
}
.details {
  margin-top: 20px;
}
.sentence {
  margin-top: 10px;
}

.sentence-text,
.translation {
  margin: 0; /* 去除默认的段落上下边距 */
  text-align: left; /* 靠左对齐 */
}
</style>
